@tailwind base;
@tailwind components;
@tailwind utilities;

/* Removes the blue highlight box on mobile */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@layer utilities {
  .animate-backwards {
    animation-direction: reverse;
  }
  .animation-perserve {
    animation-fill-mode: both;
  }

  .animation-delay-1 {
    animation-delay: -8s;
  }
  .animation-delay-2 {
    animation-delay: -16s;
  }
}

.angle-top {
  -webkit-clip-path: polygon(0 5vw, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 5vw, 100% 0, 100% 100%, 0 100%);
}

.angle-top-invert {
  -webkit-clip-path: polygon(0 0, 100% 5vw, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 5vw, 100% 100%, 0 100%);
}
